import React from "react"
import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import getAppUrl from "@src/utils/getAppUrl"
import Section from "@src/components/Section"
import FeatureGrid from "@src/components/FeatureGrid"
import Text from "@src/components/Text"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import track from "@src/utils/track"

import { ReactComponent as GoogleSheetsIcon } from "@src/icons/v2/google-sheets-icon.svg"
import { ReactComponent as AsanaIcon } from "@src/icons/v2/asana-icon.svg"
import { ReactComponent as TwitterIntegrations } from "@src/icons/v2/twitter-integrations.svg"
import { ReactComponent as GithubIntegrations } from "@src/icons/v2/github-integrations.svg"
import { ReactComponent as YoutubeIntegration } from "@src/icons/v2/youtube-integrations.svg"
import { ReactComponent as StripeIntegration } from "@src/icons/v2/stripe-integrations.svg"
import { ReactComponent as SlackIntegration } from "@src/icons/v2/slack-integrations.svg"
import YoutubeBlog from "@src/icons/v2/youtube-blog.png"

import * as styles from "./variation.module.scss"

const YoutubeIntegrations = ({ location }) => {
  const mainAppUrl = getAppUrl(location, "/dashboard/marketplace/integrations")

  return (
    <Layout
      title="Integrate YouTube on your app and build a custom workflow"
      description="Add YouTube features to your application, including the ability to upload videos, create and manage playlists, and more."
      url="https://canonic.dev/features/youtube-integrations"
      location={location}
    >
      <SplashWithIllustration
        isWhite
        illustrationComponent={<YoutubeIntegration />}
        className={styles.SocialIntegrations}
        heading="Youtube Integration"
        title="Integrate YouTube on your app and build a custom workflow"
        subtitle="Add YouTube features to your application, including the ability to upload videos, create and manage playlists, and more."
        buttons={[
          {
            title: "Clone & get started →",
            isPrimary: true,
            href: `${mainAppUrl}`,
            onClick: () => track("Start for free", location.pathname),
          },
        ]}
      />
      <Section blueGradient className={styles.SocialIntegrationsActions}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Features
        </Text.Heading>
        <Text.H2 white>Few of the Triggers and Actions</Text.H2>

        <FeatureGrid cards className={styles.SocialIntegrationsActionsCards}>
          {[
            {
              title: "Manage Channels",
              description: `Get resources such as list of channels, update the channel's metadata such as branding settings.`,
            },

            {
              title: "Manage Videos",
              description: `Get video resource such as ratings,list. Add, update, delete a video.`,
            },
            {
              title: "Manage Comments",
              description: `Get a list of comments, reply to a comment, update or delete a comment.`,
            },
            {
              title: "Manage Captions",
              description: `Get a list of caption tracks, insert a track, update a caption track or delete a caption track.`,
            },
          ]}
        </FeatureGrid>
      </Section>

      <Section white lassName={styles.SocialIntegrationsSamples}>
        <div className={styles.SocialIntegrationsSamplesWrapper}>
          <div className={styles.SocialIntegrationsSamplesWrapperFirst}>
            <Text.Heading>Sample Project</Text.Heading>
            <Text.H2 lightBlueGradient>
              Clone the project built with GitHub integration or read the guide
              on how to build it
            </Text.H2>
            <FeatureGrid cards white>
              {[
                {
                  title:
                    "Youtube to Google Sheets: How to import Youtube public data",
                  description:
                    "Learn how to access YouTube metrics through Google Sheets reporting, and get weekly updates on Slack as well as an alert.",
                  link: {
                    title: "Clone Project",
                    url: "https://app.canonic.dev/projects/61b78fc80b0203002d823376",
                  },
                },
              ]}
            </FeatureGrid>
          </div>
          <div className={styles.SocialIntegrationsSamplesWrapperSecond}>
            <div className={styles.blogCards}>
              <div>
                <figure className={styles.blogCardsFigure}>
                  <img src={YoutubeBlog} />
                </figure>
                <Text.H3>
                  Youtube to Google Sheets: How to import Youtube public data
                </Text.H3>

                <Text.P>
                  Learn how to access YouTube metrics through Google Sheets
                  reporting, and get weekly updates on Slack as well as an
                  alert.
                </Text.P>
                <a
                  href={
                    "https://dev.to/canonic/youtube-to-google-sheets-how-to-import-youtube-public-data-1b5h"
                  }
                >
                  <button>Step by Step Guide </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section lightBlue className={styles.SocialIntegrationsApp}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Benefits
        </Text.Heading>
        <Text.H2 lightBlueGradient>Pick an App to pair with</Text.H2>
        <FeatureGrid cards className={styles.SocialIntegrationsAppCards}>
          {[
            {
              icon: GoogleSheetsIcon,
              title: "Google Sheets",
              description: `Spreadsheets  |  Google `,
            },
            {
              icon: AsanaIcon,
              title: "Asana",
              description: `Project Management`,
            },
            {
              icon: GithubIntegrations,
              title: "GitHub",
              description: `Version Management`,
            },
            {
              icon: TwitterIntegrations,
              title: "Twitter",
              description: `Social Media`,
            },
            {
              icon: StripeIntegration,
              title: "Stripe",
              description: `Payment Gateway`,
            },
            {
              icon: SlackIntegration,
              title: "Slack",
              description: `Business Communication`,
            },
          ]}
        </FeatureGrid>
      </Section>
      <Section black className={styles.SocialIntegrationsBenefits}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Benefits
        </Text.Heading>
        <Text.H2 lightBlueGradient>
          Here’s why you should integrate using Canonic
        </Text.H2>
        <FeatureGrid
          vertical
          fullWidth
          className={styles.SocialIntegrationsBenefitsCards}
        >
          {[
            {
              //   icon: PlaceholderIcon,
              title: "Plenty of Inegrations",
              description: `A good deal of full featured set of integrations.`,
            },
            {
              //   icon: PlaceholderIcon,
              title: "Chanining Multiple Integration",
              description: `Multiple integrations can be chained to one another.`,
            },
            {
              //   icon: PlaceholderIcon,
              title: "Test your Data",
              description: `Test the data you are receiving on the GraphQL Playground.`,
            },
          ]}
        </FeatureGrid>
      </Section>
      <Callout location={location} title="youtubeIntegrations" />
      <CommunityCallout />
    </Layout>
  )
}

export default YoutubeIntegrations
